<template>
<div class="reset-password">
    <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal"/>
    <Loading v-if="loading"/>
    <div class="form-wrap">
        <form @submit.prevent="resetPassword" class="reset">
            <p class="login-register">
              已经注册过了?
              <router-link class="router-link" :to="{name:'Login'}">登录</router-link>
            </p>
            <h2>重置密码</h2>
            <p>忘记密码? 请填写邮箱来重置你的密码</p>
            <div class="inputs">
                <div class="input">
                    <input type="text" placeholder="邮箱" v-model="email"/>
                    <email class="icon"/>
                </div>
                <div v-show="error" class="error">
                    {{this.errorMsg}}
                </div>
            </div>
            <button>重置</button>
            <div class="angle"></div>
        </form>
        <div class="background"></div>
    </div>
</div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg"
import Modal from "../components/Modal"
import Loading from "../components/Loading"

export default {
    name:"ForgotPassword",
    data(){
      return {
        email:null,
        modalActive:false,
        modalMessage:"",
        loading:null,
        error:null,
        errorMsg:"",
      }
    },
    components:{
        email,
        Modal,
        Loading,
    },
    methods:{
      async resetPassword(){
        this.loading = true;
        var that = this
        await this.$store.dispatch('forgotPassword',{
          email:that.email
        })
        .then(()=>{
            this.error = false;
            this.errorMsg = "";
            this.loading = false;
            this.modalActive = true;
            this.modalMessage="邮件已发出，请登录邮箱，点击里面的链接重置密码！";
            //this.$router.push({name:'ResetPassword'})
        })
        .catch((err)=>{
            this.error = true;
            this.errorMsg = err.response.data.message;
            this.loading = false;
            console.log(this.errorMsg)
        })
      },
      closeModal(){
        this.modalActive = !this.modalActive;
        this.email = "";
      }
    }
}
</script>

<style lang="scss" scoped>
.reset-password {
  position: relative;
  .form-wrap {
    .reset {
      h2 {
        margin-bottom: 8px;
      }
      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}
</style>